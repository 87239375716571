@import '../../node_modules/antd/dist/antd.less';

@primary-color: #645377; // primary color for all components
@primary-6-interactive: #6f5b82;
@primary-1-interactive-background-selected-hover: #f6f0fc;
@border-radius-base: 10px;
@body-background: #fff; // #E1E1DB;
@layout-body-background: #fff;
@grid-columns: 24;
@text-color: #000;
@layout-header-color: #000;
@layout-header-background: #e1e1db;
@layout-header-height: 56px;
@layout-header-padding: 0;
@drawer-bg: #efeeeb;
// @layout-sider-background: #EFEEEB;
@tree-bg: transparent;
@checkbox-size: 24px;
// @link-color: #09B3BC; // link color
// @success-color: #52c41a; // success state color
// @warning-color: #faad14; // warning state color
// @error-color: #f5222d; // error state color
// @menu-dark-selected-item-icon-color: @white;
// @menu-dark-selected-item-text-color: @white;
// @menu-icon-size: 1.5rem;
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 4px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
// @menu-dark-bg:#5C5B5B;
// @menu-dark-submenu-bg:#5C5B5B;
// @table-padding-horizontal: 10px;
// @table-padding-vertical: 10px;

// TODO remove classes not used in html from procedures

html {
  height: 100%;
  overflow: hidden;
}

.full-height {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.text-middle {
  text-align: middle;
}

.ant-tabs .ant-tabs-content {
  height: 100%;
  padding-left: 0;
}

.ant-layout-content>div {
  height: 100%;
}

// Nos aseguramos de que los controles tengan la indentación que traían del Word.
#procedure-data .contentcontrol {
  text-indent: initial !important;
}

// Propiedades de los componentes insertados
#procedure-data .wrapper,
#procedure-data .wrapper-revised {
  min-height: 100px;

  & table {
    background-color: #fff;
  }

  & .insert_recorder {
    display: inline-block !important;

    &.signature {
      width: 120px;
      height: 62px;
    }

    &.recorder {
      width: 124px;
      height: 40px;
    }

    &.numerical {
      width: 120px;
      height: 34px;
    }

    &.readoutrtnumerical {
      width: 120px;
      height: 42px;
      vertical-align: bottom;
    }

    &.readoutrtrecorder {
      width: 124px;
      height: 42px;
      vertical-align: bottom;
    }

    &.optionlist {
      width: 175px;
      height: 32px;
    }

    &.multicell {
      width: 24px;
    }

    & .insert_recorder_text {
      font-size: 15px;
    }

    & .insert_recorder_non_text {
      font-size: 0px;
    }
  }
}

#procedure-data .wrapper-revised {
  padding-left: 5px;
  border-left: 2px red solid;
}

#procedure-data .revised:not(tr)::before {
  content: '';
  border-left: 2px red solid !important;
  padding-right: 2px;
}

#procedure-data .revised:is(tr)> :first-child {
  border-left: 2px red solid !important;
}

.wrapper .is-read .step-circle {
  box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 0px 4px #007ec3;
}

.wrapper .is-done .step-line::before {
  border-color: #007ec3 !important;
}

.insert_stepMenu {
  display: flex;
  flex-direction: row;
  min-height: 45px;
  padding-top: 5px;
}

.steps-drawer .ant-drawer-content-wrapper {
  // 100% de altura menos la altura del header y subheader
  height: calc(100% - 112px) !important;
}

.vertical-drawer {
  display: flex !important;
  justify-content: center !important;

  & .ant-drawer-content-wrapper {
    position: initial;
  }
}

.ant-drawer-content-wrapper {
  width: 400px;
}

.ant-drawer-bottom {
  & .ant-drawer-content-wrapper {
    width: 100%;
  }
}

.disabled_maneuver,
.disabled_conditional {
  opacity: 50%;
}

.hidden_tag {
  visibility: hidden;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Para sobreescribir los estilos de antd dentro del procedure-data.
#procedure-data {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bolder;
  }
}

.ant-btn>.anticon {
  line-height: 0;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-disabled .ant-checkbox-inner,
.ant-checkbox,
.ant-checkbox-inner {
  border-radius: 2px;
}

.custom-popover .ant-popover-inner,
.profile-popover .ant-popover-inner {
  border-radius: 4px;
}

.custom-popover .ant-popover-inner-content {
  padding: 8px 0;
}

.profile-popover .ant-popover-inner-content {
  padding: 8px 0 0;
}

.review-tooltip .ant-popover-inner-content {
  background: #272928;
  color: #fff;
  border-radius: 4px;
}

.review-tooltip .ant-popover-arrow {
  border-right-color: #272928 !important;
  border-bottom-color: #272928 !important;
}

//clase usada para poner colores a la búsqueda en procedimiento con Mark.js
mark {
  padding: 0;
  background-color: yellow;
  color: black;
}

.ant-input,
.ant-input-number {
  border-radius: 2px;
}

.ant-select-selector {
  border-radius: 2px !important;
}

.drawer-comments {
  & .ant-menu-item-selected {
    background-color: initial !important;

    & a {
      color: initial;
    }
  }
}

.ant-notification:has(.upload-video-notification) {
  z-index: 10 !important;
}

.ant-notification-notice-description:has(.upload-video-notification) {
  margin: 0 !important;
}

.ant-notification-notice-description:has(.upload-video-notification-final) {
  margin: 0 !important;
}

.ant-notification-notice:has(.upload-video-notification) {
  & {
    padding: 0 24px !important;
  }

  & .ant-notification-notice-message {
    margin-bottom: 0 !important;
    padding-right: 0px !important;
  }
}

.ant-notification-notice:has(.upload-video-notification-final) {
  & {
    padding: 16px 24px !important;
  }

  & .ant-notification-notice-message {
    margin-bottom: 8px !important;
  }
}

.msoIns {
  text-decoration: none !important;
  color: inherit !important;
}

@media screen {
  .header {
    display: none;
  }

  .footer {
    display: none;
  }
}

.badge-primary {
  color: @primary-6-interactive;
  border-radius: 100px;
  border: 0.5px solid @primary-6-interactive;
  background: @primary-1-interactive-background-selected-hover;
  padding: 0px 6px;
}

.departments-no-serie {
  color: @primary-6-interactive;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.page-on-scroll {
  overflow-y: auto;
}

.page-on-tab-fixed {
  overflow-y: auto;
  padding: 49px 16px 16px !important;
}

.ant-menu-horizontal {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.sticky-tab-bar {
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.bg-gray {
  background-color: #efeeeb;
}

@import './components/text/index.less';
@import './components/numerical/index.less';
@import './components/list/index.less';
@import './components/date-time/index.less';
@import './components/textarea/index.less';
@import './components/historical/index.less';