.recorder-date-time {
    & .ant-picker {
        border: 2px solid #d7d7d7;
        background-color: #efefef;

        &:hover {
            border-radius: 2px !important;
            border: 2px solid #bc2ac9 !important;
        }

        &:active {
            box-shadow: none;
            border: 2px solid #bc2ac9;
        }
    }

    & .ant-picker-disabled:hover {
        border-radius: 2px !important;
        border-color: #d9d9d9 !important;
    }
}