.recorder-list {
    &:hover {
        border: 2px solid #bc2ac9;
    }

    &:hover[data-disabled="true"] {
        border: 2px solid transparent !important;
    }

    .ant-select-selector {
        background-color: #f5f5f5 !important;

        &:hover {
            border: 1px solid #d9d9d9 !important;
        }
    }
}