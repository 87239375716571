.recorder-numerical {
    background-color: #efefef !important;

    &:hover:not([disabled]) {
        outline: 2px solid #bc2ac9;
        border: transparent;
    }

    &:hover:is([disabled]) {
        outline: transparent;
    }

    .ant-input,
    .ant-input-affix-wrapper {
        background-color: transparent;
        border: none;
    }
}