.recorder-textarea>textarea {
    background-color: #edece9 !important;
    border: none !important;
    border-radius: 2px;

    &[data-error="true"] {
        border: 2px solid #ff0000 !important;
    }

    &[disabled] {
        border: 2px solid transparent !important;
    }

    &:hover {
        background-color: #edece9 !important;
        border: 2px solid #bc2ac9 !important;
    }

    &:hover[data-error="true"] {
        border: 2px solid #ff0000 !important;
    }

    &:hover[disabled] {
        border: 2px solid transparent !important;
    }
}