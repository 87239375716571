.historical-component {
    border: 1px solid #FAC8AA !important;
    background: #FFF0E0 !important;
    cursor: pointer;

    &:focus,
    &:hover {
        border: 1px solid #FAC8AA !important;
        background: #FFF0E0 !important;
    }

    & input,
    .ant-input-affix-wrapper,
    .ant-select,
    .ant-select-selector {
        background-color: transparent !important;
        cursor: pointer;
        pointer-events: none;

        &:focus,
        &:hover {
            border: 1px solid #FAC8AA !important;
            background: #FFF0E0 !important;
        }
    }

    .ant-select-selector {
        border-color: transparent !important;
    }

    &[data-active="true"] {
        border: 2px solid #6F5B82 !important;

        &:focus,
        &:hover {
            border: 2px solid #6F5B82 !important;
        }
    }
}

.recorder>.historical-component[data-active="true"] {
    border: 1px solid transparent !important;

    &:focus,
    &:hover {
        border: 1px solid transparent !important;
    }

    & input {
        border: 2px solid #6F5B82 !important;

        &:focus,
        &:hover {
            border: 2px solid #6F5B82 !important;
        }
    }
}


.historical-component-date-time {
    cursor: pointer;
    display: inline-block;
    height: 100%;

    & .ant-picker {
        border: 1px solid #FAC8AA !important;
        background: #FFF0E0 !important;
        cursor: pointer;
        pointer-events: none;
    }

    &[data-active="true"] .ant-picker {
        border: 2px solid #6F5B82 !important;
    }
}

.historical-component-textarea {
    cursor: pointer;

    & textarea {
        border: 1px solid #FAC8AA !important;
        background: #FFF0E0 !important;
        cursor: pointer;
        pointer-events: none;
    }

    &[data-active="true"] textarea {
        border: 2px solid #6F5B82 !important;
    }
}