.recorder-input {
    border: none !important;
    background-color: #edece9 !important;

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
        background-color: #edece9 !important;
        border: 2px solid #bc2ac9;
    }

    & input {
        background-color: transparent !important;

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
            background-color: #edece9 !important;
            border: 2px solid #bc2ac9;
        }

        &:focus:is([disabled]),
        &:hover:is([disabled]) {
            background-color: #edece9 !important;
            border: 2px solid transparent;
        }
    }
}